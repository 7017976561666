import React, { useCallback } from 'react';
import { RiFunctionFill } from 'react-icons/ri';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

export const ButtonFunctions: React.FC = () => {
  const { handleChangeOpenModalFunction, isModalOpen } = usePdvOnline();

  const handleFunctions = useCallback(() => {
    handleChangeOpenModalFunction(true);
  }, [handleChangeOpenModalFunction]);

  return (
    <CustomButtonNovo
      onClick={handleFunctions}
      label="Funções (Alt + F)"
      icon={RiFunctionFill}
      disabled={isModalOpen.isOpen}
      style={{ backgroundColor: '#be9102', textTransform: 'uppercase' }}
      width="auto"
    />
  );
};
