import React, { useEffect, useState } from 'react';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { getStatusMessage } from '../../utils/getStatusMessage';
import { ButtonClearScreen } from '../ButtonClearScreen';
import { ButtonFinalizar } from '../ButtonFinalizar';
import { ButtonFunctions } from '../ButtonFunctions';
import {
  ButtonClearFinishContainer,
  MainFooterContainer,
  StatusMessageContainer,
} from './styles';
import { ButtonHelp } from '../ButtonHelp';

export const Footer: React.FC = () => {
  const [tempoExcedido, setTempoExcedido] = useState(false);

  const {
    isPdvOpen,
    isOpenModalFunction,
    handleChangeOpenModalFunction,
    statusPedido,
    handleStatusPedido,
  } = usePdvOnline();

  useEffect(() => {
    const openModalFunction = (event: KeyboardEvent) => {
      if (
        !isOpenModalFunction &&
        event.altKey &&
        (event.key === 'F' || event.key === 'f')
      ) {
        event.preventDefault();
        handleChangeOpenModalFunction(true);
      }
    };

    document.addEventListener('keydown', openModalFunction);
    return () => document.removeEventListener('keydown', openModalFunction);
  }, [handleChangeOpenModalFunction, isOpenModalFunction]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (statusPedido === 'LANCAMENTO_INCIADO') {
      timer = setTimeout(() => {
        setTempoExcedido(true);
      }, 3000);
    } else {
      setTempoExcedido(false);
    }

    return () => clearTimeout(timer);
  }, [statusPedido]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (statusPedido === 'LANCAMENTO_INCIADO') {
      timer = setTimeout(() => {
        handleStatusPedido('FALHA_LANCAMENTO');
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [handleStatusPedido, statusPedido]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (statusPedido === 'FALHA_LANCAMENTO') {
      timer = setTimeout(() => {
        handleStatusPedido(null);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [handleStatusPedido, statusPedido]);

  return (
    <MainFooterContainer>
      {!statusPedido && (
        <>
          <div className="btn-left">
            <ButtonFunctions />
            {isPdvOpen && <ButtonHelp />}
          </div>
          {isPdvOpen && (
            <ButtonClearFinishContainer>
              <ButtonClearScreen />
              <ButtonFinalizar />
            </ButtonClearFinishContainer>
          )}
        </>
      )}
      {statusPedido && (
        <StatusMessageContainer>
          {tempoExcedido && (
            <div className="buttonActions">
              <ButtonFunctions />
            </div>
          )}
          {getStatusMessage(statusPedido)}
        </StatusMessageContainer>
      )}
    </MainFooterContainer>
  );
};
