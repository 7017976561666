import React, { useCallback, useEffect } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { useFocusTabProduto } from '../../hooks/useFocusTabProduto';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { resetAbaProdutos } from '../TabProdutos/utils/resetFields';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

export const ButtonClearScreen: React.FC = () => {
  const {
    handleResetPdv,
    mainContentRef,
    formTabProduto: { resetTabProduto },
    handleIsIpv,
    handleSelectProduto,
  } = usePdvOnline();

  const { handleInputFocus } = useFocusTabProduto();

  const clearInputLeitor = useCallback(() => {
    const inputLeitor =
      mainContentRef.current?.querySelector<HTMLInputElement>(
        '[name="leitor"]',
      );
    if (inputLeitor) {
      inputLeitor.value = '';
    }
  }, [mainContentRef]);

  const handleTabProdutoClick = useCallback(() => {
    const tabProduto = mainContentRef.current?.querySelector<HTMLButtonElement>(
      '[id="tab-Produtos (Alt + 1)"]',
    );
    if (tabProduto) {
      tabProduto?.click();
      handleInputFocus('leitor');
      resetTabProduto(resetAbaProdutos);
    }
  }, [handleInputFocus, mainContentRef, resetTabProduto]);

  const onReset = useCallback(() => {
    if (mainContentRef.current) {
      handleTabProdutoClick();
      handleIsIpv(false);
      handleSelectProduto(null);
      clearInputLeitor();
      handleResetPdv();
    }
  }, [
    clearInputLeitor,
    handleIsIpv,
    handleResetPdv,
    handleSelectProduto,
    handleTabProdutoClick,
    mainContentRef,
  ]);

  useEffect(() => {
    const handleKeyFinalizaVenda = async (ev: KeyboardEvent) => {
      if (ev.altKey && (ev.key === 'L' || ev.key === 'l')) {
        ev.preventDefault();
        onReset();
      }
    };

    window.addEventListener('keydown', handleKeyFinalizaVenda);
    return () => window.removeEventListener('keydown', handleKeyFinalizaVenda);
  }, [onReset]);

  return (
    <CustomButtonNovo
      onClick={onReset}
      label="Limpar (Alt + L)"
      icon={AiOutlineClear}
      style={{ backgroundColor: '#d02925', textTransform: 'uppercase' }}
      width="auto"
    />
  );
};
