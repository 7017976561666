import styled from 'styled-components';

export const MainFooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  width: 100%;
  padding: 1rem 1.75rem;
  box-sizing: border-box;
  margin-top: auto;

  @media only screen and (max-width: 1400px) {
    padding: 0.5rem 1.75rem;
  }

  .btn-left {
    display: inline-flex;
    align-items: center;
    gap: 0.9375rem;
  }
`;

export const ButtonClearFinishContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9375rem;
`;

export const StatusMessageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  position: relative;

  font-weight: 600;
  text-transform: uppercase;
  font-size: large;

  .buttonActions {
    position: absolute;
    left: 0;
  }
`;
