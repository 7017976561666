import styled from 'styled-components';

export const ButtonHelpContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;

  background-color: #3f48cc;
  border-radius: 0.25rem;
  padding: 0.1875rem 0.75rem;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;

  a {
    font-size: 0.875rem;
    font-weight: 500;
    color: white;

    font-family: 'Jost', sans-serif;
    line-height: 1.3125rem;
  }

  animation: all 0.25s ease-in;
  user-select: none;

  height: 2.5rem;
  width: auto;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }
`;
