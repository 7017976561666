import React, { useEffect, useRef } from 'react';
import { ButtonHelpContent } from './styles';
import { IoIosHelpBuoy } from 'react-icons/io';
import { usePdvOnline } from '../../hooks/usePdvOnline';

export const ButtonHelp: React.FC = () => {
  const aRef = useRef<HTMLAnchorElement | null>(null);

  const { isOpenModalFunction } = usePdvOnline();

  useEffect(() => {
    const handleKeyFinalizaVenda = async (ev: KeyboardEvent) => {
      if (ev.key === 'F1') {
        ev.preventDefault();
        if (aRef.current && !isOpenModalFunction) aRef.current.click();
      }
    };

    window.addEventListener('keydown', handleKeyFinalizaVenda);
    return () => window.removeEventListener('keydown', handleKeyFinalizaVenda);
  }, [isOpenModalFunction]);

  return (
    <ButtonHelpContent>
      <IoIosHelpBuoy size={20} color="white" />
      <a
        href="https://ajuda.konvix.com.br/portal/pt-br/kb/articles/pdv-online"
        target="_blank"
        rel="noreferrer noopener"
        style={{
          textDecoration: 'none',
          fontSize: '0.875rem',
          lineHeight: '1.3125rem',
        }}
        ref={aRef}
      >
        AJUDA (F1)
      </a>
    </ButtonHelpContent>
  );
};
